.custom-calendar.rmdp-week-day {
  color: #481b3a;
}

.bookzy-calendar.rmdp-shadow,
.bookzy-calendar.rmdp-border {
  border: 0;
  box-shadow: unset;
  background-color: #F4F6F9;
}

.bookzy-calendar.bookzy-border {
  border: 1px solid #EBEBEB;
  border-radius: 12px;
  box-shadow: unset;
}

.bookzy-calendar .rmdp-week-day,
.MuiPickersPopper-root .MuiDayPicker-weekDayLabel {
  color: #676767;
  font-size: 12px;
  font-size: 12px;
}

.bookzy-calendar .rmdp-week div {}

.bookzy-calendar .rmdp-header-values, .MuiPickersPopper-root .MuiPickersCalendarHeader-root {
  color: #481b3a;
  font-weight: 600;
}

.bookzy-calendar .rmdp-arrow {
  color: #481b3a;
  border: solid #481b3a;
  border-width: 0 2px 2px 0;
}

.bookzy-calendar .rmdp-day.rmdp-day-hidden {
  background-color: transparent;
  color: transparent;
}

.bookzy-calendar .rmdp-day,
.bookzy-calendar .rmdp-day.rmdp-today,
.MuiPickersPopper-root .MuiPickersDay-root{
  color: #481b3a;
  font-weight: 600;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-width: 0px;
  border-color: #fff;
  box-shadow: unset;
  margin: 2px;
}


.bookzy-calendar .rmdp-day.rmdp-today span.default-current-date {
  color: #481b3a;
  background-color: #fff;
}

.bookzy-calendar .rmdp-day span{
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}

.bookzy-calendar .rmdp-day.rmdp-today span,
.bookzy-calendar .rmdp-day.rmdp-selected span,
.bookzy-calendar .rmdp-day.rmdp-selected span.selected-date,
.MuiPickersPopper-root .MuiPickersDay-root.Mui-selected,
.MuiPickersPopper-root .MuiPickersDay-root.Mui-selected:hover,
.MuiPickersPopper-root .MuiPickersDay-root.Mui-selected:focus {
  color: #fff;
  background-color: #ff5733;
  box-shadow: unset;
  /* font-size: 12px; */
}



/** preview **/
.bookzy-calendar.preview .rmdp-day,
.bookzy-calendar.preview .rmdp-day.rmdp-today,
.bookzy-calendar.preview .rmdp-day.rmdp-today span,
.bookzy-calendar.preview .rmdp-day.rmdp-today span.default-current-date,
.bookzy-calendar.preview .rmdp-day span:hover,
.bookzy-calendar.preview .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover  {
  background-color: #F4F6F9;
  color: #481b3a;
  box-shadow: unset;
}

.bookzy-calendar.preview .rmdp-day span.selected-date,
.bookzy-calendar.preview .rmdp-day.rmdp-selected,
.bookzy-calendar.preview .rmdp-day.rmdp-selected span,
.bookzy-calendar.preview .rmdp-day.rmdp-selected span.selected-date,
.bookzy-calendar.preview .rmdp-day.rmdp-selected span:hover,
.bookzy-calendar.preview .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span.selected-date:hover {
  background-color: #ff5733;
  color: #FFF;
  box-shadow: unset;
}

/** preview-click **/
.bookzy-calendar.preview-click .rmdp-day,
.bookzy-calendar.preview-click .rmdp-day.rmdp-today,
.bookzy-calendar.preview-click .rmdp-day.rmdp-today span,
.bookzy-calendar.preview-click .rmdp-day.rmdp-today span.default-current-date,
.bookzy-calendar.preview-click .rmdp-day.rmdp-selected span,
.bookzy-calendar.preview-click .rmdp-day.rmdp-selected span.selected-date,
.bookzy-calendar.preview-click .rmdp-day span:hover,
.bookzy-calendar.preview-click .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover  {
  background-color: #F4F6F9;
  color: #481b3a;
  box-shadow: unset;
}

.bookzy-calendar.preview-click .rmdp-day span.default-class-dates,
.bookzy-calendar.preview-click .rmdp-day.rmdp-selected  {
  background-color: #FFF;
  color: #481b3a;
  box-shadow: unset;
}

.bookzy-calendar.preview-click .rmdp-day span.default-selected-date,
.bookzy-calendar.preview-click .rmdp-day span.default-selected-date:hover,
.bookzy-calendar.preview-click .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span.default-selected-date:hover,
.bookzy-calendar.preview-click .rmdp-day span.default-class-dates:hover,
.bookzy-calendar.preview-click .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span.default-class-dates:hover  {
  color: #fff;
  background-color: #ff5733;
  box-shadow: unset;
}


/** bookzy-range-calendar **/
.bookzy-calendar.bookzy-range-calendar .rmdp-day,
.bookzy-calendar.bookzy-range-calendar .rmdp-day.rmdp-today,
.bookzy-calendar.bookzy-range-calendar .rmdp-day.rmdp-today span,
.bookzy-calendar.bookzy-range-calendar .rmdp-day.rmdp-today span.default-current-date,
.bookzy-calendar.bookzy-range-calendar .rmdp-day span:hover,
.bookzy-calendar.bookzy-range-calendar .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: #F4F6F9;
  color: #481b3a;
  box-shadow: unset;
}

.bookzy-calendar.bookzy-range-calendar .rmdp-day span.selected-date,
.bookzy-calendar.bookzy-range-calendar .rmdp-day.rmdp-range,
.bookzy-calendar.bookzy-range-calendar .rmdp-day.rmdp-range span,
.bookzy-calendar.bookzy-range-calendar .rmdp-day.rmdp-range span.selected-date,
.bookzy-calendar.bookzy-range-calendar .rmdp-day.rmdp-range span:hover,
.bookzy-calendar.bookzy-range-calendar .rmdp-day.rmdp-range-hover {
  background-color: #f79985;
  color: #FFF;
  box-shadow: unset;
}

.bookzy-calendar.bookzy-range-calendar .rmdp-day span.selected-date,
.bookzy-calendar.bookzy-range-calendar .rmdp-day.rmdp-range.start,
.bookzy-calendar.bookzy-range-calendar .rmdp-day.rmdp-range.end,
.bookzy-calendar.bookzy-range-calendar .rmdp-day.rmdp-range.start span,
.bookzy-calendar.bookzy-range-calendar .rmdp-day.rmdp-range.end span,
.bookzy-calendar.bookzy-range-calendar .rmdp-day.rmdp-range.start span:hover,
.bookzy-calendar.bookzy-range-calendar .rmdp-day.rmdp-range.end span:hover {
  background-color: #ff5733;
  color: #FFF;
  box-shadow: unset;
}

.bookzy-calendar.bookzy-range-calendar .rmdp-day.rmdp-disabled {
  color: #CCC;
}

/* .MuiPickersPopper-root .MuiIconButton-root .MuiSvgIcon-root */
.MuiPickersPopper-root .MuiIconButton-root.MuiIconButton-edgeStart,
.MuiPickersPopper-root .MuiIconButton-root.MuiIconButton-edgeEnd
{
  color: #481b3a;
  color: rgba(72, 27, 57, 0.54);
}
.MuiPickersPopper-root .MuiIconButton-root.MuiIconButton-edgeStart.Mui-disabled,
.MuiPickersPopper-root .MuiIconButton-root.MuiIconButton-edgeEnd.Mui-disabled
{
  color: rgba(72, 27, 57, 0.26);
}

.MuiPickersPopper-root .MuiClockPointer-root,
.MuiPickersPopper-root .MuiClock-pin
{
  background-color: #ff5733;
}
.MuiPickersPopper-root .MuiClockPointer-thumb
{
  border-color: #ff5733;
}
