@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiPopover-paper button {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  color: #000;
}

.MuiPopover-paper button:hover {
  background-color: #ccc;
  color: #FFF;
}

.react-tel-input .form-control:hover,
.react-tel-input .form-control:focus,
.MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  /* border: 1 !important; */
  border-color: #7E00DD !important;
  box-shadow: unset !important;
}

input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 19px;
  height: 19px;
  padding: 3px;
  margin-right: 10px;
  vertical-align: middle;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #32282F;
  background-color: #FFF;
  border-radius: 50%;
}

input[type="radio"]:checked {
  background-color: #32282F;
}

.initials-avatar {
  min-width: 2rem;
  min-height: 2rem;
  cursor: pointer;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  /* background-color: #000; */
  display: inline-block;
  box-sizing: border-box
}

.initials-avatar div {
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: inherit;
  font-weight: inherit
}

.rbc-servieBookings {
  color: #000 !important;
  background-color:#add8e6 !important;
  border-color:#91b7c3 !important;
}

.rbc-freeTime {
  color: #481b3a !important;
  background-color:#D7BB294D !important;
  border-color:#ad96224d !important;
}

.rbc-availableFreeSlot {
  color: #b0b0b0 !important;
  background-color:#94949436 !important;
  border-color:#94949436 !important;
}

.rbc-toolbar-label {
  font-weight: bold;
}

.rbc-today {
  background-color: #ffffff !important;
}

.rbc-allday-cell {
  display: none;
}